import React from "react";
import suspendImage from "../images/suspend.png";
import Image from "next/image";
import Link from "next/link";
import shopApi from "../api/shop";
import {ILocales} from "../components/ForCWV/Wrappers/MainLayout";
import {layoutTranslations} from "../services/translationKeys";
import {layoutProps} from "../services/layoutProps";
import {NextPage} from "next";

const Suspend: NextPage<any> = ({DomainName}) => {
    const src = typeof window !== "undefined" ? `${window.location.origin}${suspendImage?.src}` : ""
    return (
        <div className="SuspendMain">
            <div className="SuspendBody container p-0">
                <div className="Suspend">
                    <div>
                        <div className="SuspendImage">
                            <Image
                                loader={() => src}
                                width={480}
                                height={429}
                                src={src}
                                alt="Suspend Image"
                            />
                        </div>
                    </div>

                    <h1>
                        {DomainName} <br/>
                        is no longer available
                    </h1>

                    <h3>
                        This website has been archived or suspended in accordance with our{" "}
                        <Link
                            href="https://www.zegashop.com/web/terms-of-service/"
                            prefetch={false}
                        >
                            <a>Terms Of Service.</a>
                        </Link>
                    </h3>
                    <h5>
                        For more information, Please,{" "}
                        <Link
                            href="https://www.zegashop.com/web/contact-us/"
                            prefetch={false}
                        >
                            <a target="_blank">Contact us.</a>
                        </Link>
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default Suspend

export async function getServerSideProps({locale, req, res, query}: any) {
    const [{
        currencies_new = [],
        locales = [],
        default_locale_id = 1
    }] = await shopApi.getChannelInfo(req.m, req.headers, req.defaultDB.db_name)
    const {code: defaultLangCode = "en"} = locales.find(({id: langId}: ILocales) => langId === default_locale_id) || {}
    const selectedLocale = locale !== "catchAll" ? locale : defaultLangCode;
    const translationsData = await shopApi.GetTranslations(selectedLocale, layoutTranslations, req.m) || {};
    const filteredLocale = locales.filter((lang: ILocales) => lang?.code !== selectedLocale)
    const {locale_image: selectedLocaleImg, name: selectedLocaleName} = locales.find((lang: ILocales) => lang?.code === selectedLocale) || {}

    const wrapperProps = await layoutProps({
        requestHeadersDB: req.headers["x-forwarded-host"],
        requestDbName: req.defaultDB.db_name,
        requestModels: req.m,
        selectedLocale: selectedLocale,
        footerTrackItl: translationsData,
        requestLogo: req.assets.logo_image
    })

    return {
        props: {
            locales: filteredLocale,
            selectedLocaleImg,
            selectedLocaleName,
            currencies: currencies_new,
            locale: selectedLocale,
            translationsData: translationsData,
            selectedRate: currencies_new[0],
            ...wrapperProps,
        },
    };
}
